<template>
  <!-- 数据看板轮播 -->
  <div class="DataboardSwiper">
    <div class="DataboardSwiper_tit">数据看板</div>
    <div class="swiper-container" id="DataboardSwiper">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, i) in swiper_list"
          :key="i"
          :id="item.slider"
        >
          <div class="swiper-slide-img">
            <div v-if="item.w_photo == null" class="noPhoto">
              {{ item.w_name }} <br />暂无图片
            </div>
            <div v-else class="img">
              <!-- <img :src="pathUrl + item.w_photo" alt="" /> -->
              <img :src="item.w_photo" alt="" />
            </div>
          </div>
          <div class="swiper-slide-name">
            <a-tooltip placement="bottomLeft">
              <template slot="title">
                <span> {{ item.w_name }}</span>
              </template>
              {{ item.w_name }}
            </a-tooltip>
          </div>
        </div>
      </div>
      <!-- 如果需要导航按钮 -->
      <div class="sweiper_btn">
        <div class="swiper-button-prev swiper-button-white"></div>
        <div class="swiper-button-next swiper-button-white"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
export default {
  name: "DataboardSwiper",
  components: {},
  data() {
    return {
      swiper_list: [
        {
          slider: "0",
          w_photo: require("../data/dataImage/adminBoard.png"),
          w_name: "管理看板",
          path: "/BoardTotalView",
        },
        {
          slider: "1",
          w_photo: require("../data/dataImage/inspection.png"),
          w_name: "工地巡查大屏",
          path: "/PatrolView",
        },
        {
          slider: "2",
          w_photo: require("../data/dataImage/exhibitionHall.png"),
          w_name: "展厅数据看板",
          path: "/ExhibitionBoardView",
        },
        {
          slider: "3",
          w_photo: require("../data/dataImage/siteField.png"),
          w_name: "工地案场",
          path: "/CaseFieldView",
        },
      ],
    };
  },
  created() {},
  watch: {},
  mounted() {
    this.initSwiper(); //不能放在created里
  },
  methods: {
    initSwiper() {
      let vm = this;
      vm.mySwiper = new Swiper("#DataboardSwiper", {
        loop: true, // 循环模式选项 当播放到最后一张图片后，点击按钮是否会继续轮播
        slidesPerView: 1, //一次显示4个
        // slidesPerView: 5, //一次显示10个
        slidesPerGroup: 1, //一次往前移一个
        // spaceBetween: 5,
        // slidesPerColumn: 2, //多行 —— 一屏显示2行
        // loopedSlides: 1,
        // loopFillGroupWithBlank: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: false, //修改swiper的父元素时，自动初始化swiper
        autoplay: true,
        autoplay: {
          //   delay: 1000 * 60, // 自动播放的间隔时间，单位ms，默认3000ms
          delay: 2000,
          disableOnInteraction: false, // 用户操作后是否停止自动播放，默认为true，即停止。改为false，表示不会停止自动播放
        },
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 如果需要分页器 即轮播图下面的小圆点
        // pagination: {
        //   el: ".swiper-pagination",
        // },
        onSlideChangeEnd: function (swiper) {
          swiper.update();
          this.mySwiper.startAutoplay();
          this.mySwiper.reLoop();
        },
        on: {
          // slideChangeTransitionEnd: function () {
          //   // vm.currentIndex;
          //   // console.log(vm.currentIndex);
          // },
          click: function (sw) {
            // let target = sw.target;
            // console.log(target);

            const img = sw.target.src; // 获取当前点击的元素
            console.log(img.split("img")[1]);
            let imgSrc = "/img" + img.split("img")[1];
            vm.swiper_list.forEach((el) => {
              // console.log(el.w_photo);
              if (imgSrc == el.w_photo) {
                // console.log(el);
                let path = el.path;
                // 跳转到相应的看板页面
                const { href } = vm.$router.resolve({ path });
                window.open(href, "_blank");
              }
            });
            // vm.swiper_list.forEach((el) => {
            //   console.log(el.w_name);
            //   // console.log(target.src);
            //   if (target.innerText.indexOf(el.w_name) != -1) {
            //     // // 跳转到设备列表页面
            //     // localStorage.setItem("path", "DeviceList");
            //     // vm.$router.push({
            //     //   path: "/DeviceList",
            //     //   query: {
            //     //     w_name: el.w_name,
            //     //     bc_id: vm.queryParam.bc_id,
            //     //   },
            //     // });
            //   }
            //   if (target.src && target.src.indexOf(el.w_photo) != -1) {
            //     // // 跳转到设备列表页面
            //     // localStorage.setItem("path", "DeviceList");
            //     // vm.$router.push({
            //     //   path: "/DeviceList",
            //     //   query: {
            //     //     w_name: el.w_name,
            //     //     bc_id: vm.queryParam.bc_id,
            //     //   },
            //     // });
            //   }
            // });
          },
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.DataboardSwiper {
  width: 100%;
  height: 100%;
  .swiper-container {
    width: 100%;
    // height: 100%;
    // height: 85%;
    height: 86%;
    position: relative;
    // font-size: 20px;
    font-size: 14px;
    .swiper-slide {
      position: relative;
      // background-color: pink;
      cursor: pointer;
      .swiper-slide-img {
        width: 100%;
        // height: 100%;
        // height: 90%;
        height: 86%;
        position: absolute;
        // background-color: #fff;
        .noPhoto {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .img {
          height: 100%;
          width: 100%;
        }
        img {
          height: 100%;
          width: 100%;
          // border-radius: 8px 8px 8px 8px;
        }
      }
      .swiper-slide-name {
        width: 100%;
        height: 20%;
        position: absolute;
        bottom: 0;
        z-index: 999 !important;
        // background-color: pink;
        // background-image: linear-gradient(to top, #fff, #fff);
        // background: -webkit-linear-gradient(
        //   top,
        //   rgb(255, 255, 255),
        //   rgb(255, 255, 255, 0)
        // );
        // background: -moz-linear-gradient(
        //   top,
        //   rgb(255, 255, 255),
        //   rgb(255, 255, 255, 0)
        // ); /* Firefox 3.6 - 15*/
        // background: linear-gradient(
        //   to top,
        //   rgb(255, 255, 255),
        //   rgba(255, 255, 255, 0)
        // ); /* 标准的语法 */

        // color: black;
        color: #1890ff;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        // padding-bottom: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        // overflow: hidden;

        // margin-top: 10px;
      }
    }
    .sweiper_btn {
      // position: absolute;
      width: 100%;
      // bottom: 5%;
      z-index: 999;
      display: none;
    }
  }
  .swiper-container:hover {
    .sweiper_btn {
      display: block;
    }
  }
}
/deep/.swiper-button-next,
.swiper-button-prev {
  width: 6px;
  height: 10px;
}
.DataboardSwiper_tit {
  // width: 64px;
  height: 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin: 14px;
}
</style>