<template>
  <!-- <div>工地 工人统计</div> -->
  <div class="SiteworkerStatistics">
    <!-- 工地模块 -->
    <div>
      <div class="name">工地</div>
      <div class="flex">
        <div class="left">
          <div class="flex margin5-0">
            <div class="img_out">
              <img src="../../assets/images/home_img/triangle.png" alt="" />
            </div>
            <div class="width90">
              <div class="flex">
                <div>
                  <div>工地总数</div>
                  {{ ws_data.ws_sum }}
                </div>
              </div>
              <div>
                <!-- status="active" -->
                <a-progress size="small" :percent="100" :showInfo="false" />
              </div>
            </div>
          </div>
          <div class="flex margin5-0">
            <div class="img_out greenImg_out">
              <img src="../../assets/images/home_img/Vector.png" alt="" />
            </div>
            <div class="width90">
              <div class="flex">
                <div class="greenColor">
                  <div>在线数</div>
                  {{ ws_data.ws_online_num }}
                </div>
                <div class="redColor">
                  <div>离线</div>
                  {{ ws_data.ws_offline_num }}
                </div>
              </div>
              <div class="redBgcColor">
                <a-progress
                  size="small"
                  :showInfo="false"
                  :percent="100"
                  :success-percent="
                    (ws_data.ws_online_num / ws_data.ws_sum) * 100
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="flex margin5-0">
            <div class="img_out">
              <img src="../../assets/images/home_img/bold.png" alt="" />
            </div>
            <div class="width90">
              <div class="flex">
                <div>
                  <div>工地绑定数</div>
                  {{ ws_data.ws_bind_num }}
                </div>
                <div class="grayColor">
                  {{ ws_data.ws_sum - ws_data.ws_bind_num }}
                </div>
              </div>
              <div>
                <a-progress
                  size="small"
                  :percent="(ws_data.ws_bind_num / ws_data.ws_sum) * 100"
                  :showInfo="false"
                />
              </div>
            </div>
          </div>
          <div class="flex margin5-0">
            <div class="img_out">
              <img src="../../assets/images/home_img/have_work.png" alt="" />
            </div>
            <div class="width90">
              <div class="flex">
                <div class="blueColor">
                  <div>有人施工</div>
                  {{ ws_data.ws_work_num }}
                </div>
                <div class="grayColor">
                  <!-- <div>无人施工</div> -->
                  {{ ws_data.ws_nowork_num }}
                </div>
              </div>
              <div>
                <!--  :percent="100"  :success-percent="
                    (ws_data.ws_work_num / ws_data.ws_sum) * 100
                  "-->
                <a-progress
                  size="small"
                  :percent="(ws_data.ws_work_num / ws_data.ws_sum) * 100"
                  :showInfo="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 工人统计模块 -->
    <div>
      <div class="name">工人统计</div>
      <div class="flex">
        <div class="left">
          <div class="flex margin5-0">
            <div class="img_out">
              <img src="../../assets/images/home_img/user.png" alt="" />
            </div>
            <div class="width90">
              <div class="flex">
                <div>
                  <div>工人总数</div>
                  {{ w_data.w_sum }}
                </div>
              </div>
              <div>
                <!-- status="active" -->
                <a-progress size="small" :percent="100" :showInfo="false" />
              </div>
            </div>
          </div>
          <div class="flex margin5-0">
            <div class="img_out">
              <img src="../../assets/images/home_img/check-circle.png" alt="" />
            </div>
            <div class="width90">
              <div class="flex">
                <div>
                  <div>昨日出勤数</div>
                  {{ w_data.w_attendance_num }}
                </div>
              </div>
              <div>
                <a-progress
                  size="small"
                  :percent="(w_data.w_attendance_num / w_data.w_sum) * 100"
                  :showInfo="false"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="flex margin5-0">
            <div class="img_out">
              <img src="../../assets/images/home_img/smile.png" alt="" />
            </div>
            <div class="width90">
              <div class="flex">
                <div>
                  <div class="overflowHidden">已录入人脸数</div>
                  {{ w_data.w_face_num }}
                </div>
                <div class="grayColor">
                  <div>未录</div>
                  {{ w_data.w_sum - w_data.w_face_num }}
                </div>
              </div>
              <div>
                <a-progress
                  size="small"
                  :percent="(w_data.w_face_num / w_data.w_sum) * 100"
                  :showInfo="false"
                />
              </div>
            </div>
          </div>
          <div class="flex margin5-0">
            <div class="img_out">
              <img src="../../assets/images/home_img/thumbs-up.png" alt="" />
            </div>
            <div class="width90">
              <div class="flex">
                <div>
                  <div>穿工服人数</div>
                  {{ w_data.w_clothes_num }}
                </div>
                <div class="grayColor">
                  <div>未穿</div>
                  {{ w_data.w_sum - w_data.w_clothes_num }}
                </div>
              </div>
              <div>
                <a-progress
                  size="small"
                  :percent="(w_data.w_clothes_num / w_data.w_sum) * 100"
                  :showInfo="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Getwsawdata } from "@/api/home";
export default {
  name: "SiteworkerStatistics",
  components: {},
  data() {
    return {
      w_data: {}, //工人信息
      ws_data: {}, //工地信息
    };
  },
  created() {
    this.GetWsawdata();
  },
  watch: {},
  mounted() {},
  methods: {
    GetWsawdata() {
      const params = {
        ws_data: "",
        w_data: "",
      };
      Getwsawdata(params).then((res) => {
        // console.log(res.data);
        this.w_data = res.data.w_data; //工人
        this.ws_data = res.data.ws_data; //工地
      });
    },
  },
};
</script>

<style lang="less" scoped>
.SiteworkerStatistics {
  //   margin: 24px;
  margin: 10px;
  //   width: 100%;
  //   height: 100%;
  //   background-color: pink;
  .name {
    // width: 32px;
    height: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin: 14px 0;
  }
  .flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #1890ff;
  }
  .margin5-0 {
    margin: 10px 0;
    .flex {
      div {
        margin: 10px 0;
      }
    }
  }
  .img_out {
    width: 32px;
    height: 31px;
    background: #1890ff;
    border-radius: 4px 4px 4px 4px;
    // text-align: center;
    line-height: 31px;
  }
  .greenImg_out {
    background: #32cf4b;
  }
  .width90 {
    width: 90%;
    padding: 0 5px;
    // width: 40px;
    // height: 10px;
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: 400;
    // font-size: 10px;
    // font-size: 20px;
    // transform: scale(0.5);
    //这样出来的字体实际是10， font-size数值为 实际大小*2
    font-size: 20px;
    zoom: 50%;

    color: #999999;
    line-height: 10px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    .flex {
      align-items: flex-end;
      div {
        div {
          padding-bottom: 10px !important;
        }
      }
    }
  }
  //   .SiteNums {
  //     width: 100%;
  //     display: flex;
  //     justify-content: space-between;
  //     text-align: left;
  //   }
  .left {
    width: 49%;
    // background-color: pink;
  }
  .right {
    width: 49%;
    // background-color: red;
  }
  // .overflowHidden {
  //   // width: 100%;

  //   // height: 13px;
  //   // line-height: 13px;
  //   // /* 超出隐藏 显示省略号 */
  //   // white-space: nowrap;
  //   // overflow: hidden;
  //   // text-overflow: ellipsis;
  // }
}
/deep/.ant-progress-status-success .ant-progress-bg {
  background-color: #1890ff;
}
.redBgcColor {
  /deep/ .ant-progress-bg {
    background-color: #ff8080 !important;
  }
}
.redColor {
  color: #ff8080;
}
.greenColor {
  color: #32cf4b;
}
.grayColor {
  color: #999999;
  text-align: right;
}
// /deep/.margin_bottom {
//   padding-bottom: 10px !important;
// }
</style>