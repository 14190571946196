<template>
  <div class="WantDailysheet">
    <div class="WantDailysheet_name">日报</div>
    <div class="flex_img">
      <div @click="changeData(-1)" class="changeBtn">
        <!-- <img src="../../assets/images/home_img/arrow-left-circle.png" alt="" /> -->
        <
      </div>
      <div>
        <!-- :default-value="moment(date_defaultValue, dateFormat)" -->
        <a-date-picker
          @change="onChange"
          :value="date_defaultValue"
          :format="dateFormat"
        />
      </div>
      <div @click="changeData(+1)" class="changeBtn">
        <!-- <img src="../../assets/images/home_img/arrow-right-circle.png" alt="" /> -->
        >
      </div>
    </div>
    <!-- 日报报表 -->
    <!-- <a-spin size="large" :spinning="spinning" class="a_span" /> -->
    <div class="Sheet" v-for="(item, index) in resData" :key="index">
      <!-- :title="$Format(item.date, 'date')" -->
      <a-descriptions bordered class="title">
        <a-descriptions-item label="管理日报" :span="3">
          已绑定工地数量{{ item.ws_bind_num }}个，无人打卡工地数量{{
            item.ws_nowork_num
          }}个，已打卡工地数量{{ item.ws_work_num }}个
          <br />

          新绑定工地
          <span class="colorBlue" @click="ToSiteList('新绑定', item.date)">
            {{ item.ws_newbind_num }}</span
          >
          个，签到工人<span
            class="colorBlue"
            @click="ToWorkerAttend('签到', '3', item.date)"
          >
            {{ item.ws_aworker_num }} </span
          >人次， 项目经理巡查现场<span
            class="colorBlue"
            @click="ToWorkerAttend('项目经理', '2', item.date)"
          >
            {{ item.ws_jlworker_num }} </span
          >人次、 管家巡查现场<span
            class="colorBlue"
            @click="ToWorkerAttend('管家', '1', item.date)"
          >
            {{ item.ws_gjworker_num }} </span
          >人次、 管理人员巡查现场<span
            class="colorBlue"
            @click="ToWorkerAttend('其他', 'qita', item.date)"
          >
            {{ item.ws_glworker_num }} </span
          >人次、 工地整洁度优工地数量（<span
            class="colorBlue"
            @click="ToSiteNeat('整洁度优', '1', item.date)"
          >
            {{ item.ws_clean_num }} </span
          >个工地/占比
          {{
            item.ws_bind_num != 0
              ? Math.floor((item.ws_clean_num / item.ws_bind_num) * 10000) / 100
              : 0
          }}
          %）
          <!-- 工地整洁度优工地数量   计算方法 -->
          <a-tooltip placement="bottom">
            <template slot="title">
              <span>工地整洁度优工地数量</span>
            </template>
            <a-icon type="exclamation-circle" />
          </a-tooltip>

          、 工地整洁度差工地数量（<span
            class="colorBlue"
            @click="ToSiteNeat('整洁度差', '3', item.date)"
          >
            {{ item.ws_bad_num }} </span
          >个工地/占比
          {{
            item.ws_bind_num != 0
              ? Math.floor((item.ws_bad_num / item.ws_bind_num) * 10000) / 100
              : 0
          }}
          %）
          <!-- 工地整洁度差工地数量   计算方法 -->
          <a-tooltip placement="bottom">
            <template slot="title">
              <span>工地整洁度差工地数量</span>
            </template>
            <a-icon type="exclamation-circle" />
          </a-tooltip>
          、 员工抽烟（<span
            class="colorBlue"
            @click="ToAlarmEvent('员工抽烟', item.date)"
          >
            {{ item.s_workersmoke_num }} </span
          >人 / {{ item.s_smoke_sum }} 人次）

          <br />

          重点需要督促的项目经理<br />
          整洁度差工地数量最多项目经理（<span
            class="colorBlue"
            @click="ToPrincipal(item.w_bad_name, '2', item.date)"
          >
            {{ item.w_bad_name }}</span
          >）、抽烟工地数量最多项目经理（<span
            class="colorBlue"
            @click="ToPrincipal(item.w_smoke_name, '2', item.date)"
          >
            {{ item.w_smoke_name }}</span
          >）、摄像机歪曲最多项目经理（
          <span
            class="colorBlue"
            @click="ToPrincipal(item.w_camera_name, '2', item.date)"
          >
            {{ item.w_camera_name }}
          </span>
          ） </a-descriptions-item
        ><a-descriptions-item label="运维日报">
          摄像机歪曲数量及占比（<span
            class="colorBlue"
            @click="ToSiteNeat('摄像头歪曲', '4', item.date)"
            >{{
              item.ws_bind_num != 0
                ? Math.floor((item.bc_camera_num / item.ws_bind_num) * 10000) /
                  100
                : 0
            }}%</span
          >）
          <!-- 摄像头歪曲数量及占比   计算方法 -->
          <a-tooltip placement="bottom">
            <template slot="title">
              <span>摄像头歪曲数量及占比</span>
            </template>
            <a-icon type="exclamation-circle" />
          </a-tooltip>
          、重点督促签到项目经理（{{ item.a_warn_jl_name }}/未签到工地数{{
            item.a_warn_jl_num
          }}个）
        </a-descriptions-item></a-descriptions
      >
    </div>
    <!-- <a-pagination
      show-quick-jumper
      v-model="pageNo"
      :total="totlePage"
      @change="onChangePage"
      showSizeChanger
      @showSizeChange="sizeChange"
      :pageSizeOptions="sizeList"
      :defaultPageSize="defaultPageSize"
      style="margin: 20px 0; text-align: right"
    /> -->
  </div>
</template>
<script>
import { SSearch } from "@/components";
import { getDaily } from "@/api/dataStatistics.js";
import moment from "moment";

// 搜索条件
const queryItems = [
  {
    label: "分公司查询",
    value: "bc_id",
    type: "select",
    selectOption: "CompanyList",
    defaultValue: "",
  },
  {
    label: "日报日期查询",
    value: "a_time",
    type: "dateRange",
    range: ["time_begin", "time_end"],
    keyType: "WorkerAttend",
    defaultValue: [],
  },
];
const orderParam = ["bc_id", "time_begin", "time_end", "pageNo", "pageSize"];
export default {
  name: "WantDailysheet",
  components: { SSearch },
  data() {
    this.queryItems = queryItems;
    return {
      queryParam: {
        bc_id: "",
        pageNo: 1,
        pageSize: 5,
      },
      showType: "Organzational",
      datepickerType: "day",
      ShowDateType: "dayShow",
      dateValue: {},
      treeExpandedKeys: [],
      value: undefined,
      resData: "",
      //当前页数，当前条数
      pageNo: 1,
      pageSize: 5,
      totlePage: 50,
      sizeList: ["5", "10", "20", "30", "40"], //一页能显示条数
      today: "",
      defaultPageSize: 5,
      spinning: true,
      date_defaultValue: "",
      // date_defaultValue: "2024-03-11",

      dateFormat: "YYYY-MM-DD",
    };
  },
  created() {
    this.today = new Date(new Date().setHours(0, 0, 0, 0)).getTime(); //
    let yesterday = this.today - 1000 * 3600 * 24;
    let fiveDaysAgo = this.today - 1000 * 3600 * 24 * 5;
    this.queryItems[1].defaultValue = [
      moment(this.$Format(fiveDaysAgo / 1000)),
      moment(this.$Format(yesterday / 1000)),
    ];
    this.queryParam.time_begin = fiveDaysAgo / 1000;
    this.queryParam.time_end = yesterday / 1000;
    // console.log(
    //   this.$Format(yesterday / 1000),
    //   this.$Format(fiveDaysAgo / 1000)
    // );
    this.loadData();
    this.date_defaultValue = moment(Date.parse(new Date()));
    // this.date_defaultValue = 1711509340000;

    // console.log(
    //   "date_defaultValue",
    //   this.date_defaultValue,
    //   Date.parse(new Date())
    // );
  },
  watch: {},
  computed: {},
  methods: {
    async loadData() {
      this.queryParam.pageNo = this.pageNo;
      // this.queryParam.pageSize = this.pageSize;
      this.queryParam.pageSize = 1;
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      await getDaily(params).then((res) => {
        this.resData = res.data.data;
        // this.totlePage = Math.ceil(res.data.sum / this.queryParam.pageSize);
        this.totlePage = res.data.sum;
        this.spinning = false;
        // console.log(" this.totlePage ", this.totlePage);
      });
    },
    // onChangePage(pageNumber) {
    //   console.log("Page: ", pageNumber);
    //   this.queryParam.pageNo = pageNumber;
    //   // this.queryParam.pageSize = pageSize;
    //   this.loadData();
    // },
    //  分页显示条数的变化
    // sizeChange(current, size) {
    //   console.log(current, size);
    //   // this.queryParam.pageNo = current;
    //   // this.queryParam.pageSize = size;
    //   this.pageNo = current;
    //   this.pageSize = size;
    //   this.loadData();
    // },
    //查询触发
    fetchDaily(value) {
      console.log(value);
      this.queryParam = value;

      // 再次获取接口
      this.loadData();
    },
    // 在建工地 跳转到工地管理->工地列表，查询条件绑定状态为已绑定，时间为当前时间，其他查询条件默认全部或者空
    // 新绑定工地  跳转到工地列表，附带日期->设备绑定日期，其他默认全部或者空；
    ToSiteList(type, date) {
      // console.log(type);
      // localStorage.setItem("path", "SiteList");
      // const { href } = this.$router.resolve({
      //   path: "/SiteList",
      //   query: {
      //     ws_bind_time_start: date,
      //     ws_bind_time_end: date,
      //     ws_bind_status: "0",
      //     ws_status: "0",
      //     d_status: "0",
      //     bc_name: this.queryParam.bc_id,
      //   },
      // });
      // window.open(href, "_blank");
      this.$store.commit("SET_CURRENT", "SiteList");
      this.$router.push({
        path: "/SiteList",
        query: {
          ws_bind_time_start: date,
          ws_bind_time_end: date,
          ws_bind_status: "0",
          ws_status: "0",
          d_status: "0",
          bc_name: this.queryParam.bc_id,
        },
      });
    },
    // 签到工人人次 数  跳转到人员管理->人员考勤，时间为昨天，其他默认全部；
    // 项目经理巡查现场人次  跳转到人员管理->人员考勤，岗位为项目经理，时间为昨天，其他默认；
    //  管家巡查现场人次  跳转到人员管理->人员考勤，岗位为管家，时间为昨天，其他默认全部或者空；
    //  管理人员巡查现场人次 跳转到人员管理->人员考勤，岗位为其他，时间为昨天，
    // w_post 岗位   2项目经理   1工程管家  “”全部
    ToWorkerAttend(worker, w_post, date) {
      // console.log(worker);
      // localStorage.setItem("path", "WorkerAttend");
      // const { href } = this.$router.resolve({
      //   path: "/WorkerAttend",
      //   query: {
      //     start_time: date,
      //     end_time: date,
      //     w_post: w_post,
      //     bc_id: this.queryParam.bc_id,
      //   },
      // });
      // window.open(href, "_blank");
      this.$store.commit("SET_CURRENT", "WorkerAttend");
      this.$router.push({
        path: "/WorkerAttend",
        query: {
          start_time: date,
          end_time: date,
          w_post: w_post,
          bc_id: this.queryParam.bc_id,
        },
      });
    },

    // 整洁度优工地数量  跳转到工地形象->整洁管理->查询条件是时间昨天，其他查询条件不变，整洁度类型为优的工地列表；
    //  整洁度差工地数量  跳转到工地形象->整洁管理->查询条件时间昨天，其他查询条件不变，整洁度类型为差的工地列表；
    // 摄像头歪曲数量及占比：跳转到工地形象->整洁管理，查询条件整洁度类型“摄像头歪曲”
    ToSiteNeat(Neat, e_events, date) {
      // console.log(Neat);
      // localStorage.setItem("path", "SiteNeat");
      // const { href } = this.$router.resolve({
      //   path: "/SiteNeat",
      //   query: {
      //     e_time_start: date,
      //     e_time_end: date,
      //     e_events: e_events,
      //     bc_id: this.queryParam.bc_id,
      //   },
      // });
      // window.open(href, "_blank");
      this.$store.commit("SET_CURRENT", "SiteNeat");
      this.$router.push({
        path: "/SiteNeat",
        query: {
          e_time_start: date,
          e_time_end: date,
          e_events: e_events,
          bc_id: this.queryParam.bc_id,
        },
      });
    },

    //  昨天施工现场员工抽烟  点击跳转到工地形象->报警事件管理，查询条件报警事件默认“有人抽烟”，人员识别“员工”，时间昨天，其他查询条件不变；
    ToAlarmEvent(type, date) {
      // console.log(type);
      // localStorage.setItem("path", "AlarmEvent");
      // const { href } = this.$router.resolve({
      //   path: "/AlarmEvent",
      //   query: {
      //     e_time_start: date,
      //     e_time_end: date,
      //     e_events: "smoking",
      //     eventworker_type: "1",
      //     bc_id: this.queryParam.bc_id,
      //   },
      // });
      // window.open(href, "_blank");
      this.$store.commit("SET_CURRENT", "AlarmEvent");
      this.$router.push({
        path: "/AlarmEvent",
        query: {
          e_time_start: date,
          e_time_end: date,
          e_events: "smoking",
          eventworker_type: "1",
          bc_id: this.queryParam.bc_id,
        },
      });
    },
    // 跳转到工地形象统计-按人员统计  参数：  负责人姓名   岗位   时间
    ToPrincipal(w_name, w_post, find_time) {
      // localStorage.setItem("path", "SiteImage");
      // const { href } = this.$router.resolve({
      //   path: "/SiteImage",
      //   query: {
      //     w_name: w_name,
      //     w_post: w_post,
      //     find_time: find_time,
      //     showType: "Principal",
      //     bc_id: this.queryParam.bc_id,
      //   },
      // });
      // window.open(href, "_blank");
      this.$store.commit("SET_CURRENT", "SiteImage");
      this.$router.push({
        path: "/SiteImage",
        query: {
          w_name: w_name,
          w_post: w_post,
          find_time: find_time,
          showType: "Principal",
          bc_id: this.queryParam.bc_id,
        },
      });
    },
    // ▪点击跳转到工地形象->整洁管理，查询条件整洁度事件默认“整洁度差”，人员识别姓名，时间，其他查询条件不变；

    // 日期
    onChange(date, dateString) {
      console.log(date, dateString);
      let whenTime = moment(date).startOf("day").format("x");
      console.log(whenTime);
      this.queryParam.time_begin = whenTime / 1000;
      this.queryParam.time_end = whenTime / 1000;
      this.loadData();
    },
    // 点击 前一天 后一天
    changeData(val) {
      // console.log(
      //   this.$Format(moment(this.date_defaultValue).startOf("day").format("x"))
      // );
      let whenTime = moment(this.date_defaultValue).startOf("day").format("x");
      let oneDay = 60 * 60 * 24 * 1000;
      this.date_defaultValue = moment(this.date_defaultValue + oneDay * val);
      // console.log(this.$Format(this.date_defaultValue / 1000));
      this.queryParam.time_begin = this.date_defaultValue / 1000;
      this.queryParam.time_end = this.date_defaultValue / 1000;
      this.loadData();
    },
  },
};
</script>
<style lang="less" scoped>
.WantDailysheet {
  // padding-top: 5px;
  margin: 0 10px;

  // width: 100%;
  // height: 100%;
  .WantDailysheet_name {
    height: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin: 14px 0;
  }
  .a_span {
    margin-top: 20%;
    margin-bottom: 10%;
  }
  .Sheet {
    color: black;
    text-align: left;
    margin-bottom: 15px;
    .title {
      font-size: 20px;
    }
    /deep/ .ant-descriptions-item-label {
      // width: 115px;
      width: 60px;
      font-size: 12px;
      transform: scale(0.9);

      // font-weight: 500;
      // padding-left: 10px;
      // padding-right: 10px;
    }
    /deep/ .ant-descriptions-title {
      font-size: 18px;
      margin-bottom: 0;
    }
    /deep/ .ant-descriptions-item-content {
      font-size: 12px;
      // transform: scale(0.9);
    }

    .colorBlue {
      color: blue;
      cursor: pointer;
    }
  }
  /deep/.ant-descriptions-bordered .ant-descriptions-item-content,
  /deep/ .ant-descriptions-bordered .ant-descriptions-item-label {
    padding: 0;
  }
  .flex_img {
    display: flex;
    // justify-content: space-around;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  /deep/ .ant-input {
    border: none;
    text-align: center;
    font-weight: 800;
    font-size: 14px;
    color: #1890ff;
  }
  /deep/.ant-calendar-picker-icon {
    display: none;
  }
}
.changeBtn {
  width: 10px;
  height: 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  // line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  cursor: pointer;
}
</style>
