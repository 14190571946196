<template>
  <div class="HomeMaster">
    <div class="top">
      <div class="top_search">
        <a-input placeholder="" class="top_search_input" />
        <a-button type="primary">搜索</a-button>
      </div>
      <div class="top_btns_flex">
        <div class="top_btns" v-for="item in ToRouter" :key="item.name">
          <div @click="GoToOthers(item)" class="RouterName">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <!-- 我要掌握 -->
      <div class="center1">
        <WantDimensionality />
      </div>
      <!-- 数据看板轮播 -->
      <div class="center2">
        <DataboardSwiper />
      </div>
      <!-- 工地  工人统计 -->
      <div class="center3">
        <SiteworkerStatistics />
      </div>
      <!-- 设备状态 -->
      <div class="center4">
        <DeviceState />
      </div>
    </div>
    <div class="bottom">
      <!-- 日报 -->
      <div class="bottom1">
        <WantDailysheet />
      </div>
      <!-- 趋势图 -->
      <div class="bottom2">
        <TendencyChart />
      </div>
      <!-- 排行榜 -->
      <div class="bottom3">
        <RankList />
        <!-- 工地案场
        <div class="SiteLivestream">
        <VideoSwiper />
        </div>
        告警
        <div class="SiteWarn">
        <SiteWarn />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import DataboardSwiper from "@/views/home/DataboardSwiper.vue";
import WantDimensionality from "./WantDimensionality.vue";
import SiteworkerStatistics from "./SiteworkerStatistics.vue";
import DeviceState from "./DeviceState";
import WantDailysheet from "./WantDailysheet.vue";
import TendencyChart from "./TendencyChart.vue";
import SiteWarn from "./SiteWarn.vue";
import VideoSwiper from "./VideoSwiper.vue";
import RankList from "./RankList.vue";
export default {
  name: "HomeMaster",
  components: {
    DataboardSwiper,
    WantDimensionality,
    SiteworkerStatistics,
    DeviceState,
    WantDailysheet,
    TendencyChart,
    SiteWarn,
    VideoSwiper,
    RankList,
  },
  data() {
    return {
      ToRouter: [
        { index: 0, title: "工地列表", name: "SiteList" },
        { index: 1, title: "考勤统计", name: "AttendStatistics" },
        { index: 2, title: "设备统计", name: "DeviceStatistics" },
        { index: 3, title: "设备列表", name: "DeviceList" },
        { index: 4, title: "报警事件管理", name: "AlarmEvent" },
        { index: 5, title: "人员考勤", name: "WorkerAttend" },
        { index: 6, title: "日报", name: "DailySheet" },
        { index: 7, title: "角色权限", name: "UserList" },
      ],
    };
  },
  created() {},
  watch: {},
  mounted() {
    // 拿到存在本地的访问过的页面
    let ToRouter = JSON.parse(sessionStorage.getItem("ToRouter"));
    // 对页面根据点击次数排序
    ToRouter.sort((a, b) => {
      return b.num - a.num;
    });
    // console.log("home======ToRouter", ToRouter);
    // 截取常用的前十个页面
    this.ToRouter = ToRouter.splice(0, 10);
  },
  methods: {
    GoToOthers(el) {
      // console.log("GoToOthers", el.name);
      // localStorage.setItem("path", el.name);

      this.$router.push({
        path: "./" + el.name,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.HomeMaster {
  width: 100%;
  min-width: 1215px;
  // width: 1920px;
  // height: 100%;
  height: 98%;
  margin-top: 10px;
  background-color: #f9f9f9;
  .top {
    width: 100%;
    // height: 150px;
    // height: 112px;
    height: 81px;
    // height: 12%;
    background: #ffffff;
    // background-color: pink;
    // padding-top: 23px;
    // border: 1px solid black;
    .RouterName {
      cursor: pointer;
    }
    .top_search {
      // width: 54px;
      // height: 26px;
      text-align: left;
      display: flex;
      /deep/ .top_search_input {
        width: 223px !important;
        margin-right: 8px;
      }
    }
    .top_btns_flex {
      // margin-top: 26px;
      margin-top: 13px;
      display: flex;
      .top_btns {
        // width: 82px;
        // height: 30px;
        background: #eef2f8;
        // background-color: pink;
        border-radius: 8px 8px 8px 8px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        // line-height: 14px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        margin-right: 40px;
        padding: 5px 10px;
      }
    }
  }
  .center {
    // height: 347px;
    height: 330px;
    width: 100%;
    // background-color: pink;
    // height: 30%;
    // background-color: pink;
    // margin-top: 24px;
    margin-top: 11px;
    display: flex;
    justify-content: space-between;

    .center1,
    .center2,
    .center3,
    .center4 {
      height: 100%;
      // border: 1px solid pink;
      background-color: #fff;
      border-radius: 8px 8px 8px 8px;
    }
    .center1 {
      // width: 390px;
      width: 25%;
    }
    .center2 {
      width: 350px;
      // width: 22%;
    }
    .center3 {
      // width: 357px;
      width: 23%;
    }
    .center4 {
      // width: 397px;
      width: 25%;
      height: 50%;
      // background-color: pink;
    }
  }
  .bottom {
    // width: 390px;
    // height: 439px;
    height: 390px;
    // height: 38%;
    margin-top: 24px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .bottom1,
    .bottom2,
    .bottom3 {
      background: #ffffff;
      height: 100%;
      border-radius: 8px;
      // border: 1px solid pink;
    }
    .bottom1 {
      // width: 390px;
      width: 25%;
      // width: 30%;
    }
    .bottom2 {
      // width: 766px;
      // width: 44%;
      width: 47%;
    }
    .bottom3 {
      // width: 365px;
      // width: 24%;
      width: 25%;
      // margin-top: -15%;
      margin-top: -180px;
      height: 145%;
      // background-color: pink;
      // .SiteLivestream,
      // .SiteWarn {
      //   width: 100%;
      //   height: 190px;
      //   // background-color: pink;
      //   // border-radius: 8px;
      // }
      // .SiteWarn {
      //   margin-top: 8px;
      //   // overflow: hidden;
      // }
    }
  }
}
</style>