<template>
  <!-- <div>设备状态</div> -->
  <div class="DeviceState">
    <div class="top_flex">
      <div class="DeviceState_name">设备状态</div>
      <div class="DeviceState_select">
        <a-select
          v-if="IsShowCompanyList"
          v-model="CompaniesValue"
          placeholder="请选择分公司"
          @change="selectChange"
          class="search-default-value"
        >
          <a-select-option
            v-for="opt in filters.CompanyList"
            :key="opt.value"
            :value="opt.value"
            >{{ opt.label }}</a-select-option
          >
        </a-select>
      </div>
    </div>
    <div class="flex">
      <div class="left">
        <div class="flex margin5-0">
          <div class="img_out">
            <img src="../../assets/images/home_img/credit-card.png" alt="" />
          </div>
          <div class="width90">
            <div class="flex">
              <div>
                <div>设备库存</div>
                {{ deviceData.d_sum }}
              </div>
            </div>
            <div>
              <a-progress size="small" :percent="100" :showInfo="false" />
            </div>
          </div>
        </div>
        <div class="flex margin5-0">
          <div class="img_out greenImg_out">
            <img src="../../assets/images/home_img/bar-chart.png" alt="" />
          </div>
          <div class="width90">
            <div class="flex">
              <div class="greenColor">
                <div>设备在线数</div>
                {{ deviceData.d_online }}
              </div>
              <div class="redColor">
                <div>离线</div>
                {{ deviceData.d_sum - deviceData.d_online }}
              </div>
            </div>
            <div class="redBgcColor">
              <a-progress
                size="small"
                :percent="100"
                :success-percent="
                  (deviceData.d_online / deviceData.d_sum) * 100
                "
                :showInfo="false"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="flex margin5-0">
          <div class="img_out">
            <img src="../../assets/images/home_img/airplay.png" alt="" />
          </div>
          <div class="width90">
            <div class="flex">
              <div>
                <div>启用设备数</div>
                {{ deviceData.d_use }}
              </div>
              <div class="grayColor">
                <div>未启用</div>
                {{ deviceData.d_sum - deviceData.d_use }}
              </div>
            </div>
            <div>
              <a-progress
                size="small"
                :percent="(deviceData.d_use / deviceData.d_sum) * 100"
                :showInfo="false"
              />
            </div>
          </div>
        </div>
        <div class="flex margin5-0">
          <div class="img_out">
            <img src="../../assets/images/home_img/bold.png" alt="" />
          </div>
          <div class="width90">
            <div class="flex">
              <div>
                <div>已绑定设备数</div>
                {{ deviceData.d_bind }}
              </div>
              <div class="grayColor">
                <div>未绑定</div>
                {{ deviceData.d_sum - deviceData.d_bind }}
              </div>
            </div>
            <div>
              <a-progress
                size="small"
                :percent="(deviceData.d_bind / deviceData.d_sum) * 100"
                :showInfo="false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetCompanies } from "@/api/device";
import { Getdevicedata } from "@/api/home";
export default {
  name: "DeviceState",
  components: {},
  data() {
    return {
      CompaniesValue: "",
      filters: {},
      IsShowCompanyList: false,
      deviceData: {},
    };
  },
  created() {
    this.GetCompanies();
  },
  watch: {},
  mounted() {
    this.GetDevicedata();
  },
  methods: {
    GetCompanies() {
      GetCompanies()
        .then((res) => {
          this.filters.CompanyList = [];
          this.filters.CompanyList.push({ value: "", label: "全部" });
          for (let index = 0; index < res.data.length; index++) {
            const element = res.data[index];
            this.filters.CompanyList.push({
              value: element.bc_id,
              label: element.bc_name,
            });
          }
          // console.log(this.filters);
        })
        .then(() => {
          this.IsShowCompanyList = true;
        });
    },
    selectChange(value) {
      console.log(value);
      this.GetDevicedata(value);
    },
    GetDevicedata(bc_id) {
      const params = {
        bc_id: bc_id,
      };
      Getdevicedata(params).then((res) => {
        // console.log(res.data);
        this.deviceData = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.DeviceState {
  margin: 10px;
  .DeviceState_name {
    //   width: 64px;
    height: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    // margin: 14px 0;
  }
  .DeviceState_select {
    text-align: left;
  }
  .flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #1890ff;
    // margin: 20px 0;
  }
  .margin5-0 {
    margin: 10px 0;
    .flex {
      div {
        // margin: 10px 0;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
  .img_out {
    width: 32px;
    height: 31px;
    background: #1890ff;
    border-radius: 4px 4px 4px 4px;
    // text-align: center;
    line-height: 31px;
  }
  .greenImg_out {
    background: #32cf4b;
  }
  .width90 {
    width: 87%;
    padding: 0 5px;
    // width: 40px;
    // height: 10px;
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: 400;
    // font-size: 10px;
    // font-size: 20px;
    // transform: scale(0.5);
    //这样出来的字体实际是10， font-size数值为 实际大小*2
    font-size: 20px;
    zoom: 50%;
    color: #999999;
    line-height: 10px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    .flex {
      align-items: flex-end;

      div {
        div {
          padding-bottom: 10px !important;
        }
      }
    }
  }
}
.grayColor {
  color: #999999;
  text-align: right;
}
/deep/.ant-progress-status-success .ant-progress-bg {
  background-color: #1890ff;
}
.redBgcColor {
  /deep/ .ant-progress-bg {
    background-color: #ff8080 !important;
  }
}
.redColor {
  color: #ff8080;
}
.greenColor {
  color: #32cf4b;
}
.top_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 16px;
  line-height: 16px;
  margin: 14px 0;
}
</style>