<template>
  <div class="WantDimensionality">
    <div class="center1_wyzw">我要掌握</div>
    <!-- 工人维度 -->
    <div class="center1_gr">
      <!--   defaultVisible="true" -->
      <a-popover
        placement="rightTop"
        overlayClassName="popstyle"
        :getPopupContainer="
          (triggerNode) => {
            return triggerNode.parentNode;
          }
        "
      >
        <template slot="content">
          <p
            v-for="item in center1_gr"
            :key="item.id"
            @click="GoToDetail(item, '工人维度')"
          >
            {{ item.name }}
          </p>
        </template>
        <template slot="title">
          <!-- <span >{{ workerList[0].name }}</span> -->
        </template>
        <div>
          <img src="../../assets/images/home_img/users1.png" alt="" />
          <div>工人维度</div>
        </div>
      </a-popover>
    </div>
    <!-- 访客维度 -->
    <div class="center1_fk">
      <a-popover
        placement="rightTop"
        overlayClassName="popstyle"
        :getPopupContainer="
          (triggerNode) => {
            return triggerNode.parentNode;
          }
        "
      >
        <template slot="content">
          <p
            v-for="item in center1_fk"
            :key="item.id"
            @click="GoToDetail(item, '访客维度')"
          >
            {{ item.name }}
          </p>
        </template>
        <div>
          <img src="../../assets/images/home_img/user.png" alt="" />
          <div>访客维度</div>
        </div>
      </a-popover>
    </div>
    <!-- 工地维度 -->
    <div class="center1_gd">
      <a-popover
        placement="rightTop"
        overlayClassName="popstyle"
        :getPopupContainer="
          (triggerNode) => {
            return triggerNode.parentNode;
          }
        "
      >
        <template slot="content">
          <p
            v-for="item in center1_gd"
            :key="item.id"
            @click="GoToDetail(item, '工地维度')"
          >
            {{ item.name }}
          </p>
        </template>

        <div>
          <img src="../../assets/images/home_img/layers.png" alt="" />
          <div>工地维度</div>
        </div>
      </a-popover>
    </div>
    <!-- 业主维度 -->
    <div class="center1_yz">
      <a-popover
        placement="rightTop"
        overlayClassName="popstyle"
        :getPopupContainer="
          (triggerNode) => {
            return triggerNode.parentNode;
          }
        "
      >
        <template slot="content">
          <p
            v-for="item in center1_yz"
            :key="item.id"
            @click="GoToDetail(item, '业主维度')"
          >
            {{ item.name }}
          </p>
        </template>
        <div>
          <img src="../../assets/images/home_img/unlock.png" alt="" />
          <div>业主维度</div>
        </div>
      </a-popover>
    </div>
    <!-- 管理维度 -->
    <div class="center1_gl">
      <a-popover
        placement="rightTop"
        overlayClassName="popstyle"
        :getPopupContainer="
          (triggerNode) => {
            return triggerNode.parentNode;
          }
        "
      >
        <template slot="content">
          <p
            v-for="item in center1_gl"
            :key="item.id"
            @click="GoToDetail(item, '管理维度')"
          >
            {{ item.name }}
          </p>
        </template>
        <div>
          <img src="../../assets/images/home_img/copy.png" alt="" />
          <div>管理维度</div>
        </div>
      </a-popover>
    </div>
  </div>
</template>

<script>
export default {
  name: "WantDimensionality",
  components: {},
  data() {
    return {
      center1_gr: [
        {
          id: 0,
          pageId: "WorkerInfo",
          name: "基本信息",
        },
        {
          id: 1,
          pageId: "ProjectExperience",
          name: "项目经历",
        },
        {
          id: 2,
          pageId: "EvaluationRecord",
          name: "评价记录",
        },
        {
          id: 3,
          pageId: "OrderRank",
          name: "等级/勋章",
        },
        {
          id: 4,
          pageId: "BreakRecord",
          name: "违章记录",
        },
        {
          id: 5,
          pageId: "BecomplainedRecord",
          name: "被投诉记录",
        },
        {
          id: 6,
          pageId: "CleanChange",
          name: "施工工地整洁度变化",
        },
        {
          id: 7,
          pageId: "AcceptanceRecord",
          name: "开工完工验收记录",
        },
      ],
      center1_fk: [
        {
          id: 0,
          pageId: "VisitorVisit",
          name: "陌生访客到访图片/视频",
        },
      ],
      center1_gd: [
        {
          id: 0,
          pageId: "SiteInfo",
          name: "基本信息",
        },
        {
          id: 1,
          pageId: "SiteDuration",
          name: "工期信息",
        },
        {
          id: 2,
          pageId: "SiteAttendance",
          name: "施工工人考勤信息",
        },
        {
          id: 3,
          pageId: "SitePatrol",
          name: "监管人员巡查信息",
        },
        {
          id: 4,
          pageId: "SiteStylistdirect",
          name: "设计人员现场指导信息",
        },
        {
          id: 5,
          pageId: "SiteAccept",
          name: "现场验收信息",
        },
        {
          id: 6,
          pageId: "SiteRequiredpatrol",
          name: "按照规定巡查",
        },
        {
          id: 7,
          pageId: "SiteRequireaccept",
          name: "按照规定现场验收",
        },
        {
          id: 8,
          pageId: "SiteRemotepatrol",
          name: "远程巡查信息",
        },
        {
          id: 9,
          pageId: "SiteVidicon",
          name: "工地摄像机在线分析",
        },
        {
          id: 10,
          pageId: "SiteBreak",
          name: "工地违章信息",
        },
        {
          id: 11,
          pageId: "SiteClean",
          name: "工地整洁度变化趋势",
        },
        {
          id: 12,
          pageId: "SiteComplaint",
          name: "项目投诉记录",
        },
      ],
      center1_yz: [
        {
          id: 0,
          pageId: "OwnerInfo",
          name: "业主资料",
        },
        {
          id: 1,
          pageId: "OwnerVideoaccess",
          name: "视频访问记录",
        },
        {
          id: 2,
          pageId: "OwnerVisit",
          name: "现场到访记录",
        },
        {
          id: 3,
          pageId: "OwnerInteract",
          name: "业主互动记录",
        },
        {
          id: 4,
          pageId: "OwnerFeedback",
          name: "业主信息反馈(含投诉)",
        },
        {
          id: 5,
          pageId: "OwnerPromise",
          name: "业主承诺管理",
        },
        {
          id: 6,
          pageId: "OwnerProjectwarn",
          name: "业主项目工期预警记录",
        },
        {
          id: 7,
          pageId: "OwnerQualitywarn",
          name: "施工质量预警",
        },
        {
          id: 8,
          pageId: "OwnerWarnrecord",
          name: "工地报警记录",
        },
      ],
      center1_gl: [
        {
          id: 0,
          pageId: "ManageSupervise",
          name: "监管人员督察",
        },
        {
          id: 1,
          pageId: "ManageUnit",
          name: "管理下基层",
        },
        {
          id: 2,
          pageId: "ManageInspect",
          name: "规定动作核查",
        },
        {
          id: 3,
          pageId: "ManageComplaint",
          name: "业主反馈/投诉",
        },
        {
          id: 4,
          pageId: "ManageFindproblem",
          name: "发现问题",
        },
        {
          id: 5,
          pageId: "ManageRework",
          name: "返工分析",
        },
        {
          id: 6,
          pageId: "ManagePostpone",
          name: "延期分析",
        },
        {
          id: 7,
          pageId: "ManageQuality",
          name: "质量分析",
        },
      ],
    };
  },
  created() {},
  watch: {},
  mounted() {},
  methods: {
    GoToDetail(item, page) {
      console.log(item, page);
      this.$router.push({
        path: "/" + item.pageId,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.WantDimensionality {
  width: 100%;
  height: 100%;
  //   min-width: 1136px;
  position: relative;
  .center1_wyzw {
    position: absolute;
    height: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin: 14px;
  }
  .center1_gr,
  .center1_fk,
  .center1_gd,
  .center1_yz,
  .center1_gl {
    width: 80px;
    height: 80px;
    // background: rgba(24, 144, 255, 0.2);
    // border-radius: 50%;
    text-align: center;
    // line-height: 76px;
    line-height: 30px;
    padding-top: 5px;
    position: absolute;

    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 14px;
    // color: #333333;
    color: #ffffff;
    font-style: normal;
    text-transform: none;
    cursor: pointer;
    background-image: url("../../assets/images/home_img/Polygon 34.png");
    background-repeat: no-repeat;

    background-size: 80px 76px;
  }

  .center1_gr {
    // margin-left: 36%;
    margin-left: 15%;
    margin-top: 63px;
  }
  .center1_fk {
    // margin-left: 70px;
    // margin-left: 9%;
    margin-left: 15%;
    margin-top: 190px;
  }
  .center1_gd {
    // margin-left: 244px;
    // margin-left: 58%;
    right: 15%;
    // margin-top: 37px;
    margin-top: 63px;
  }
  .center1_yz {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
  .center1_gl {
    // margin-left: 216px;
    // margin-left: 58%;
    right: 15%;
    // margin-top: 213px;
    margin-top: 190px;
    // margin-top: 201px;
  }
}

// /deep/ .ant-popover-content {
//   border-radius: 8px;
// }
/deep/ .ant-popover-inner {
  border-radius: 8px;
  overflow: hidden;
  // background-color: pink;
}
/deep/ .ant-popover-inner-content {
  // border-radius: 8px 8px 0 0;
  // border-radius: 8px;
  margin: 0;
  padding: 0;
  p {
    width: 167px;
    border-bottom: 1px solid rgba(218, 214, 214, 0.507);
    line-height: 44px;
    font-weight: 800;
    margin: 0;
    padding: 0 10px;
    // background: #1890ff;
  }
  p:nth-child(1) {
    border-radius: 8px 8px 0 0;
  }
  // p:last-child {
  //   // border-radius: 0 0 8px 8px;
  // }
  p:hover {
    background: #1890ff;
    color: white;
    cursor: pointer;
  }
}
</style>