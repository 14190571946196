<template>
  <!-- <div>抽烟趋势图</div> -->
  <div class="">
    <div class="TendencyChart">
      <div key="1" tab="抽烟人数趋势图">
        <div class="DeviceState_select">
          <a-select
            v-if="IsShowCompanyList"
            v-model="CompaniesValue"
            placeholder="请选择分公司"
            @change="selectChange"
            class="search-default-value"
          >
            <a-select-option
              v-for="opt in filters.CompanyList"
              :key="opt.value"
              :value="opt.value"
              >{{ opt.label }}</a-select-option
            >
          </a-select>
          <!-- 日期组件改版 -->
          <div class="SiteNeat_date">
            <span class="dateRangeBTNName">
              <a-button
                value="small"
                @click="ChangePanel('month')"
                class="dateRangeBTN"
                >月</a-button
              >
              <a-button
                value="small"
                @click="ChangePanel('week')"
                class="dateRangeBTN"
                >周</a-button
              >
              <a-button
                value="small"
                @click="ChangePanel('date')"
                class="dateRangeBTN"
                >日</a-button
              >
            </span>
            <!-- 日 -->
            <div class="datePick_out" v-show="dateShow">
              <a-date-picker
                class="start_datePicker"
                :value-format="dateFormatValue"
                format="YYYY-MM-DD"
                :ranges="{
                  今天: [moment().startOf('day'), moment().endOf('day')],
                  当月: [moment().startOf('month'), moment().endOf('month')],
                }"
                @change="changeRange_start(item, $event)"
                @openChange="handleStartOpenChange"
              /><span class="dateRange_line">~</span>
              <a-date-picker
                class="start_datePicker"
                :value-format="dateFormatValue"
                format="YYYY-MM-DD"
                :ranges="{
                  今天: [moment().startOf('day'), moment().endOf('day')],
                  当月: [moment().startOf('month'), moment().endOf('month')],
                }"
                @change="changeRange_end(item, $event)"
              />
            </div>
            <!-- 周 -->
            <div class="datePick_out" v-show="weekShow">
              <a-week-picker
                placeholder="请选择周"
                :value-format="dateFormatValue"
                @change="changeRangeWeek_start(item, $event)"
              /><span class="dateRange_line">~</span>
              <a-week-picker
                placeholder="请选择周"
                :value-format="dateFormatValue"
                @change="changeRangeWeek_end(item, $event)"
              />
            </div>
            <!-- 月 -->
            <div class="datePick_out" v-show="monthShow">
              <a-month-picker
                placeholder="请选择月"
                :value-format="dateFormatValue"
                @change="changeRangeMonth_start(item, $event)"
              /><span class="dateRange_line">~</span>
              <a-month-picker
                placeholder="请选择月"
                :value-format="dateFormatValue"
                @change="changeRangeMonth_end(item, $event)"
              />
            </div>
          </div>
        </div>
        <div id="mainDaka" style="width: 100%; height: 300px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { GetCompanies } from "@/api/device";
import moment from "moment";
import { Getnoworkernumpic } from "@/api/home";
export default {
  name: "TendencychartCard",
  components: {},
  data() {
    return {
      CompaniesValue: "",
      filters: {},
      IsShowCompanyList: false,
      queryParam: {
        bc_id: "",
        find_type: "1",
        time_begin: Date.parse(new Date()) / 1000 - 3600 * 24 * 7,
        time_end: Date.parse(new Date()) / 1000,
      },
      dateFormatValue: "X",
      defaultValueStart: "",
      defaultValueEnd: "",
      item: "date",
      monthShow: false,
      weekShow: false,
      dateShow: true,
      option: {
        tooltip: {
          trigger: "item",
          // formatter: "{c}人", //{b}：name; {c}:value
          formatter: function (params) {
            var result = "";
            result += params.name + "<br />";
            result += params.seriesName + ": " + params.value;
            return result;
          },
        },
        xAxis: {
          type: "category",
          data: ["2024/1", "2024/2", "2024/3", "2024/4"],
          boundaryGap: false, //坐标轴两边不留白
          // 取消横坐标文字太多自动隐藏
          axisLabel: {
            // interval: 0,
            // rotate: 20, //文字倾斜
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              // 设置背景横线
              // color: "none",
            },
          },
        },
        series: [
          {
            name: "抽烟人数",
            data: [120, 200, 150, 300],
            type: "line",
          },
        ],
        dataZoom: [
          // {
          //   type: "slider",
          //   show: true,
          //   xAxisIndex: [0],
          //   left: "9%",
          //   bottom: -5,
          //   // start: 100, // 缩放的开始位置的百分比，0 - 100
          //   // end: 50, // 缩放的结束位置的百分比，0 - 100
          //   startValue: 0, // 缩放的开始位置的数值，只有在工具栏的缩放行为的事件中存在。
          //   endValue: 7, // 缩放的结束位置的数值，只有在工具栏的缩放行为的事件中存在。
          // },
          {
            start: 0, //默认为0
            end: 100 - 1500 / 31, //默认为100
            type: "slider",
            show: true,
            xAxisIndex: [0],
            handleSize: 0, //滑动条的 左右2个滑动条的大小
            height: 8, //组件高度
            left: 50, //左边的距离
            right: 40, //右边的距离
            bottom: 26, //右边的距离
            handleColor: "#ddd", //h滑动图标的颜色
            handleStyle: {
              borderColor: "#cacaca",
              borderWidth: "1",
              shadowBlur: 2,
              background: "#ddd",
              shadowColor: "#ddd",
            },
            fillerColor: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
              {
                //给颜色设置渐变色 前面4个参数，给第一个设置1，第四个设置0 ，就是水平渐变
                //给第一个设置0，第四个设置1，就是垂直渐变
                offset: 0,
                color: "#1890FF",
              },
              {
                offset: 1,
                color: "#5ccbb1",
              },
            ]),
            backgroundColor: "#ddd", //两边未选中的滑动条区域的颜色
            showDataShadow: false, //是否显示数据阴影 默认auto
            showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
            handleIcon:
              "M-292,322.2c-3.2,0-6.4-0.6-9.3-1.9c-2.9-1.2-5.4-2.9-7.6-5.1s-3.9-4.8-5.1-7.6c-1.3-3-1.9-6.1-1.9-9.3c0-3.2,0.6-6.4,1.9-9.3c1.2-2.9,2.9-5.4,5.1-7.6s4.8-3.9,7.6-5.1c3-1.3,6.1-1.9,9.3-1.9c3.2,0,6.4,0.6,9.3,1.9c2.9,1.2,5.4,2.9,7.6,5.1s3.9,4.8,5.1,7.6c1.3,3,1.9,6.1,1.9,9.3c0,3.2-0.6,6.4-1.9,9.3c-1.2,2.9-2.9,5.4-5.1,7.6s-4.8,3.9-7.6,5.1C-285.6,321.5-288.8,322.2-292,322.2z",
            filterMode: "filter",
          },
          //下面这个属性是里面拖到
          {
            type: "inside",
            show: true,
            xAxisIndex: [0],
            start: 0, //默认为1
            end: 100 - 1500 / 31, //默认为100
          },
        ],
      },
    };
  },
  created() {
    this.GetCompanies();
  },

  mounted() {
    this.initEChart();

    // this.GetSmokeData();
  },
  watch: {
    "queryParam.bc_id": {
      handler(newVal) {
        console.log("queryParam.bc_id", newVal);
        this.initEChart();
      },
      deep: true,
    },
    "queryParam.time_end": {
      handler(newVal) {
        console.log("queryParam.time_end", newVal);

        if (newVal != "") {
          this.initEChart();
        }
      },
      deep: true,
    },
  },
  methods: {
    moment,
    callback(key) {
      // console.log(key);
    },
    // 绘制图表
    // 抽烟趋势图
    initEChart(e) {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("mainDaka"));

      // 获取数据
      const orderParam = ["bc_id", "find_type", "time_begin", "time_end"];
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      let Y_key = [];
      let X_data = [];

      return Getnoworkernumpic(params)
        .then((res) => {
          // 抽烟趋势图
          res.data.noworker_data.forEach((el) => {
            if (el.date) {
              Y_key.push(el.num);
              X_data.push(el.date);
            }
          });
          this.option.xAxis.data = X_data;
          this.option.series[0].data = Y_key;
        })
        .then(() => {
          // 绘制图表
          myChart.setOption(this.option);
        });
      // 绘制图表
      // myChart.setOption(option);
    },

    GetCompanies() {
      GetCompanies()
        .then((res) => {
          this.filters.CompanyList = [];
          this.filters.CompanyList.push({ value: "", label: "全部" });
          for (let index = 0; index < res.data.length; index++) {
            const element = res.data[index];
            this.filters.CompanyList.push({
              value: element.bc_id,
              label: element.bc_name,
            });
          }
          // console.log(this.filters);
        })
        .then(() => {
          this.IsShowCompanyList = true;
        });
    },
    selectChange(value) {
      // console.log(value);
      this.queryParam.bc_id = value;
    },
    // 切换年月日选择
    ChangePanel(mode) {
      this.monthShow = false;
      this.weekShow = false;
      this.dateShow = false;
      let show = mode + "Show";
      // console.log(mode, show);
      this[show] = true;
      let type = { date: 1, week: 2, month: 3 };
      function formatData(a) {
        return type[a];
      }
      this.queryParam.find_type = formatData(mode);
      // console.log(this.queryParam.find_type);
      // let today = new Date(new Date().setHours(0, 0, 0, 0)).getTime(); //当天0点的时间戳;
      //单个日期查询-- 切换年月日时，把原有的默认日期给清除
      this.queryParam.time_begin = "";
      this.queryParam.time_end = "";
    },
    // 打开面板时，调用方法，显示前一月和当前月的日期
    openChange(status) {
      // console.log(status); //true  false
      if (this.isFist == "YES" && status) {
        setTimeout(() => {
          const pre = document.getElementsByClassName(
            "ant-calendar-prev-month-btn"
          );
          pre[0].click();
        });
        setTimeout(() => {
          const next = document.getElementsByClassName(
            "ant-calendar-prev-month-btn"
          );
          if (next[1]) {
            next[1].click();
          }
        }, 10);
      }
      if (!status) {
        this.isFist = "NO";
      }
    },
    changeRange_start(type, dates) {
      // console.log("1111", type, dates, this.$Format(dates));
      this.isFist = "NO";
      this.queryParam.time_begin = dates;
    },
    changeRange_end(type, dates) {
      console.log(type, dates, this.$Format(dates));
      this.isFist = "NO";
      this.queryParam.time_end = dates;
    },

    changeRangeWeek_start(type, dates) {
      console.log(type, dates, this.$Format(dates));
      // console.log(type, dates);
      this.isFist = "NO";
      this.queryParam.time_begin = this.$beginOfWeek(dates * 1000);
      console.log("周开始", this.queryParam.time_begin);
    },

    changeRangeWeek_end(type, dates) {
      console.log(type, dates);
      this.isFist = "NO";
      this.queryParam.time_end = this.$endOfWeek(dates * 1000);
    },

    changeRangeMonth_start(type, dates) {
      console.log(type, dates);
      this.isFist = "NO";
      this.queryParam.time_begin = this.$beginOfMonth(dates * 1000);
    },
    changeRangeMonth_end(type, dates) {
      // dates = Date.parse(dates) / 1000;
      console.log(type, dates);
      this.isFist = "NO";
      this.queryParam.time_end = this.$endOfMonth(dates * 1000);
    },
    handleStartOpenChange(open) {
      console.log(open);
      if (!open) {
        // this.endOpen = true;
      }
      // this.openChange(true);
      this.isFist = "NO";
    },
  },
};
</script>

<style lang="less" scoped>
.TendencyChart {
  width: 100%;
  height: 100%;
  text-align: left;
  // div {
  //   width: 100%;
  //   height: 80%;
  // }
}
/deep/.DeviceState_select {
  // div {
  //   text-align: left;
  //   // height: 20px;
  //   width: 250px;
  // }
  display: flex;
  justify-content: space-between;
}
#main,
#mainClose,
#mainDaka {
  height: 300px;
}
.SiteNeat_date {
  display: flex;
  flex-wrap: nowrap;
  // background: pink;
  width: 55%;
}
/deep/ .dateRangeBTN {
  padding: 2px !important;
  // margin-right: 1px;
}
.dateRangeBTNName {
  display: flex;
  flex-wrap: nowrap;
  // width: 18%;
}
.dateRange_display {
  display: flex;
  flex-wrap: nowrap;
}
.start_datePicker_only {
  width: 100%;
}
.datePick_out {
  display: flex;
  flex-wrap: nowrap;
  width: 82%;
  // margin-right: 0;
  // .start_datePicker {
  //   // width: 49%;
  // }
}
.start_datePicker_only {
  width: 100%;
}
.dateRange_line {
  color: #ccc;
  padding: 0 3px;
}
.search-default-value {
  width: 35%;
}
</style>