import request from "@/utils/request";

const api = {
  wsawdata: "/dsmcore/api/qzw/wsawdata", // 全掌握首页工地工人信息
  devicedata: "/dsmcore/api/qzw/devicedata", // 设备状态
  smokenumpic: "/dsmcore/api/qzw/smokenumpic", //抽烟人数
  noworkernumpic: "/dsmcore/api/qzw/noworkernumpic", //未打卡
  SiteWsdetail: "/dsmcore/api/qzw/wsdetail", //工地维度-基本信息
  ranklist: "/dsmcore/api/qzw/ranklist", //全掌握首页排行榜
};

export function Getwsawdata(params) {
  return request({
    url: api.wsawdata,
    method: "post",
    params,
  });
}
export function Getdevicedata(params) {
  return request({
    url: api.devicedata,
    method: "post",
    params,
  });
}
export function Getsmokenumpic(params) {
  return request({
    url: api.smokenumpic,
    method: "post",
    params,
  });
}
export function Getnoworkernumpic(params) {
  return request({
    url: api.noworkernumpic,
    method: "post",
    params,
  });
}
export function GetSiteWsdetail(params) {
  return request({
    url: api.SiteWsdetail,
    method: "post",
    params,
  });
}

export function GetRanklist(params) {
  return request({
    url: api.ranklist,
    method: "post",
    params,
  });
}
