<template>
  <!-- <div>工地告警</div> -->
  <div class="SiteWarn">
    <div class="warnTop">告警</div>
    <!-- <div class="warnList">
      <div
        v-for="(item, index) in warnList"
        :key="index"
        class="warn"
        :style="{
          backgroundColor: item.bgColor,
        }"
      >
        {{ item.siteName + item.warnType }}
      </div>
    </div> -->
    <div class="warn_marquee">
      <marquee loop="1" class="marquee_flex">
        <span
          v-for="(item, index) in warnList1"
          :key="index"
          :style="{
            backgroundColor: item.bgColor,
          }"
          class="mar_left"
          >{{ item.siteName + item.warnType }}
        </span>
      </marquee>
      <marquee loop="1" class="marquee_flex">
        <span
          v-for="(item, index) in warnList2"
          :key="index"
          :style="{
            backgroundColor: item.bgColor,
          }"
          class="mar_left"
          >{{ item.siteName + item.warnType }}
        </span>
      </marquee>
      <marquee loop="1" class="marquee_flex">
        <span
          v-for="(item, index) in warnList3"
          :key="index"
          :style="{
            backgroundColor: item.bgColor,
          }"
          class="mar_left"
          >{{ item.siteName + item.warnType }}
        </span>
      </marquee>
    </div>
  </div>
</template>

<script>
export default {
  name: "SiteWarn",
  components: {},
  data() {
    return {
      warnList: [
        {
          id: 0,
          siteName: "工地A",
          warnType: "延期一天",
          bgColor: "",
        },
        {
          id: 1,
          siteName: "工地B",
          warnType: "水电节点提前1天",
          bgColor: "",
        },
        {
          id: 2,
          siteName: "工地C",
          warnType: "业主反馈地面杂乱",
          bgColor: "",
        },
        {
          id: 3,
          siteName: "工地D",
          warnType: "瓦工节点提前1天",
          bgColor: "",
        },
        {
          id: 4,
          siteName: "工地E",
          warnType: "陌生访客近三日20+",
          bgColor: "",
        },
        {
          id: 5,
          siteName: "工地F",
          warnType: "延期一天",
          bgColor: "",
        },
        {
          id: 6,
          siteName: "工地G",
          warnType: "陌生访客近三日10+",
          bgColor: "",
        },
        {
          id: 7,
          siteName: "工地H",
          warnType: "摄像头歪曲",
          bgColor: "",
        },
        {
          id: 8,
          siteName: "工地H",
          warnType: "整洁度差",
          bgColor: "",
        },
        {
          id: 9,
          siteName: "工地H",
          warnType: "整洁度差",
          bgColor: "",
        },
      ],
      bgcColor: ["#FFD1D1", "#FFEEC4", "#D9D9D9", "#D4D1FF", "#D4D1FF"],
      marqueeText: "",
      textWidth: "",
      containerWidth: "",
      warnList1: [],
      warnList2: [],
      warnList3: [],
    };
  },
  created() {
    this.changeBgc();
  },
  watch: {},
  mounted() {
    let num = Math.floor(this.warnList.length / 3);
    this.warnList1 = this.warnList.slice(0, num);
    this.warnList2 = this.warnList.slice(num, num * 2);
    this.warnList3 = this.warnList.slice(num * 2);
    // console.log("this.warnList1", this.warnList1);
    // console.log("this.warnList2", this.warnList2);
    // console.log("this.warnList3", this.warnList3);
  },
  methods: {
    changeBgc() {
      let number = "";
      for (var i = 0; i < 24; i++) {
        // number =
        //   "#" + // 下舍入（0-1随机数 乘以 255）转换为16进制
        //   Math.floor(Math.random() * 255).toString(16) +
        //   Math.floor(Math.random() * 255).toString(16) +
        //   Math.floor(Math.random() * 255).toString(16);
        // 追加到 lists 中
        // this.warnList[i].bgColor = number;
        // console.log(number);
        let bgc = Math.floor(Math.random() * 5);
        this.warnList[i].bgColor = this.bgcColor[bgc];
        // console.log(bgc);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.SiteWarn {
  // background-color: red;
  border: 2px #ccc dashed;
}
.warnTop {
  line-height: 50px;
  text-align: left;
  padding-left: 50px;
  font-weight: 800;
}
.warnList {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  .warn {
    // width: auto;
    // width: 100px;
    padding: 10px 20px;
    border-radius: 50px;
    margin: 10px;
  }
}
// 跑马灯
.warn_marquee {
  // height: calc(100%-60px);
  height: 70%;
  // background-color: pink;
}
.marquee_flex {
  // display: inline-block;
  display: flex;
  align-items: center;
  // flex-wrap: wrap;
  // background-color: pink;
  // height: 45px;
  height: 33%;
  text-align: center;
}
.mar_left {
  margin-left: 100px;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 16px !important;
}
</style>