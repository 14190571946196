<template>
  <div>
    <div class="RankName">排行榜</div>
    <div class="DeviceState_select">
      <div>
        <a-select
          v-if="IsShowCompanyList"
          v-model="CompaniesValue"
          placeholder="请选择分公司"
          @change="selectChange"
          class="search-default-value"
        >
          <a-select-option
            v-for="opt in filters.CompanyList"
            :key="opt.value"
            :value="opt.value"
            >{{ opt.label }}</a-select-option
          >
        </a-select>
      </div>
      <div class="datePick_flex">
        <a-date-picker
          v-model="startValue"
          :disabled-date="disabledStartDate"
          format="YYYY-MM-DD"
          placeholder="开始日期"
          @openChange="handleStartOpenChange"
        />
        至
        <a-date-picker
          v-model="endValue"
          :disabled-date="disabledEndDate"
          format="YYYY-MM-DD"
          placeholder="结束日期"
          :open="endOpen"
          @openChange="handleEndOpenChange"
        />
      </div>
    </div>
    <div class="tabsName">
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="1" tab="工地整洁度优">
          <!-- 工地整洁度管家 -->
        </a-tab-pane>
        <a-tab-pane key="2" tab="穿工服次数">
          <!-- force-render -->
          <!-- 穿工服次数 -->
        </a-tab-pane>
        <a-tab-pane key="3" tab="摄像机歪曲">
          <!-- 摄像机歪曲 -->
        </a-tab-pane>
        <a-tab-pane key="4" tab="抽烟次数">
          <!-- 抽烟次数 -->
        </a-tab-pane>
      </a-tabs>
    </div>
    <!-- table表格 -->
    <div class="tableDiv">
      <s-table
        ref="table"
        size="small"
        rowKey="ws_name"
        :columns="tableColumns"
        :data="loadData"
        :showPagination="false"
        :scroll="{ y: 440 }"
        tableLayout="fixed"
        @change="changeTable"
      >
        <span slot="整洁度优次数" class="TitleFlex">
          <div>整洁度优次数</div>
          <div>
            <a-tooltip>
              <template slot="title">所选时间段内工地累计评优次数</template>
              <a-icon type="question-circle" class="ws_nameTitle_icon" />
            </a-tooltip>
          </div>
        </span>
        <span
          slot="ws_name"
          slot-scope="text, record, index"
          class="echars_out"
        >
          <!-- <span>{{ text }}</span> -->
          <div
            style="height: 20px; width: 100px"
            :ref="'echars' + index"
            class="echarsDiv"
          ></div>
        </span>
      </s-table>
    </div>
  </div>
</template>
//   
<script>
import { GetCompanies } from "@/api/device";
import { GetSiteWsdetail, GetRanklist } from "@/api/home";
import { STable } from "@/components";
import moment from "moment";
import * as echarts from "echarts";

const columns = [
  {
    title: "排名",
    width: "80px",
    // scopedSlots: { customRender: "ws_name" },
    dataIndex: "ws_jl_name_id",
    align: "center",
    sorter: (a, b) => a.ws_jl_name_id - b.ws_jl_name_id,
    // ellipsis: true,
  },
  {
    title: "工地",
    width: "100px",
    // scopedSlots: { customRender: "ws_name" },
    dataIndex: "ws_name",
    align: "center",
    ellipsis: true,
  },
  {
    // title: "整洁度优次数",
    // width: "50px",
    scopedSlots: { customRender: "ws_name" },
    dataIndex: "ws_jl_name_id",
    align: "center",
    slots: { title: "整洁度优次数" },
  },
];
const orderParam = ["ws_name", "ws_owner_name", "bc_id", "pageNo", "pageSize"];

export default {
  name: "RankList",
  components: { STable },
  data() {
    this.columns = columns;
    return {
      CompaniesValue: "",
      IsShowCompanyList: false,
      filters: {},
      startValue: null,
      endValue: null,
      endOpen: false,
      queryParam: {
        pageSize: 20,
      },
      tableData: [],
    };
  },
  created() {
    this.tableColumns = columns;
    this.GetCompanies();
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
    this.initEchars();
  },
  watch: {
    startValue(val) {
      console.log("startValue", moment(val).startOf("day").format("x"));
    },
    endValue(val) {
      console.log("endValue", moment(val).startOf("day").format("x"));
    },
  },
  methods: {
    moment,
    // 选择公司
    GetCompanies() {
      GetCompanies()
        .then((res) => {
          this.filters.CompanyList = [];
          this.filters.CompanyList.push({ value: "", label: "全部" });
          for (let index = 0; index < res.data.length; index++) {
            const element = res.data[index];
            this.filters.CompanyList.push({
              value: element.bc_id,
              label: element.bc_name,
            });
          }
          // console.log(this.filters);
        })
        .then(() => {
          this.IsShowCompanyList = true;
        });
    },
    selectChange(value) {
      console.log(value);
      //   this.GetDevicedata(value);
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);

      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetSiteWsdetail(params).then((res) => {
        res.data.pageNo = res.data.page;
        res.data.totalCount = res.data.sum;
        this.tableData = res.data;
        return res.data;
      });
    },
    changeTable(pagination, filters, sorter, { currentDataSource }) {
      console.log(pagination, filters, sorter, { currentDataSource });
    },
    disabledStartDate(startValue) {
      const endValue = this.endValue;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.startValue;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
    // 标签页返回值
    callback(key) {
      console.log(key);
    },
    initEchars() {
      setTimeout(() => {
        // console.log(this.tableData.data);
        this.tableData.data.forEach((el, index) => {
          // console.log(el.ws_jl_name_id);
          let myChart = echarts.init(this.$refs["echars" + index]);
          var option = {
            tooltip: {
              trigger: "item",
              formatter: "{c}个工地", //{b}：name; {c}:value
            },
            grid: {
              // x: 0,
              y: "5%",
              // right: "8%",
              left: "22%",
              bottom: "0%", //默认60
            },
            xAxis: {
              // type: "category",
              // data: ["222"],
              // data: [el.ws_jl_name_id],
              boundaryGap: false, //坐标轴两边不留白
              min: 0,
              max: 1000,
              // splitLine: {
              //   lineStyle: {
              //     // 设置背景横线
              //     color: "#807a7a83",
              //   },
              // },
              show: false, // 不显示坐标轴线、坐标轴刻度线和坐标轴上的文字
            },
            yAxis: {
              type: "category",
              // data: ["2024/1"],
              data: [el.ws_jl_name_id],
              axisTick: {
                show: false, // 不显示坐标轴刻度线
              },
              axisLine: {
                show: false, // 不显示坐标轴线
              },
              axisLabel: {
                show: false, // 不显示坐标轴上的文字
              },
              splitLine: {
                show: false, // 不显示网格线
              },
            },
            series: [
              {
                name: "抽烟人数",
                data: [el.ws_jl_name_id],
                // data: [],
                type: "bar",
                label: {
                  show: true, //内部显示数值
                  position: "inside", // outside外部显示  inside 内部显示
                  formatter: `{c}`,
                },
              },
            ],
          };
          // option.xAxis.data = el.ws_jl_name_id;
          // option.series[0].data = el.ws_jl_name_id;
          // 绘制图表
          myChart.setOption(option);
          window.addEventListener("resize", () => {
            myChart.resize();
          });
        });
      }, 1000);
    },
  },
};
</script>
<style lang="less" scoped>
.RankName {
  //   width: 48px;
  width: 100%;
  height: 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin: 0 0 0 24px;
}
.search-default-value {
  width: 100px;
}
.DeviceState_select {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 29px;
}
.datePick_flex {
  display: flex;
  width: 65%;
}

.tableDiv {
  height: 445px;
  overflow: hidden;
}
/deep/.ant-table-bordered .ant-table-thead > tr > th,
/deep/.ant-table-bordered .ant-table-tbody > tr > td {
  border-right: none;
  border-bottom: none;
}
/deep/.ant-table-small {
  border: none;
}
/deep/.ant-slider-track {
  background-color: #1890ff !important;
}
.echars_out {
  display: inline-block;
  //background-color: pink;
  height: 30px;
  // margin-top: 10px;
}
/deep/.echarsDiv {
  padding: 0 !important;
  margin: 0 !important;
  margin-top: 20px;
}
.TitleFlex {
  display: flex;
  align-items: center;
  .Title_icon {
    margin-right: 10px;
    padding-top: 10px;
  }
}
</style>