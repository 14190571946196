<template>
  <!-- 数据看板轮播 -->
  <div class="VideoSwiper">
    <video
      autoplay
      controls
      width="100%"
      height="190px"
      id="myVideo"
      crossorigin="*"
      style="background: black; object-fit: cover"
      :poster="posterImg"
    ></video>
    <div class="btns">
      <div @click="changeVideo(-1)" class="pointer_icon">
        <a-icon type="left" />
      </div>
      <div class="btns_name">工地案场</div>
      <div @click="changeVideo(1)" class="pointer_icon">
        <a-icon type="right" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  GetSiteVideo,
  StopSiteVideo,
  OnCall,
  OffCall,
  CameraMove,
  CameraReset,
  CameraRestart,
  CameraStatus,
  videoSignal,
  getSitPreset,
  Setdefault,
  GetWsbinddevices,
} from "@/api/video";
import { GetServiceList } from "@/api/site";
import flvjs from "flv.js";
import { urlConfig } from "@/config/config";
const orderParam = [
  "ws_name",
  "w_name",
  "w_gj_name",
  "bc_name",
  "nowork",
  "ws_bind_time_start",
  "ws_bind_time_end",
  "ws_bind_status",
  "ws_status",
  "ws_service_type",
  "ws_attendance",
  "ws_only",
  "d_status",
  "ws_dispatch",
  "bc_id",
  "cs_id",
  "d_imei",
  "pageNo",
  "pageSize",
];
export default {
  name: "VideoSwiper",
  components: {},
  data() {
    return {
      video: {
        com_only: "",
        video_url: "",
        audio_url: "",
        stream_id: "",
        push_stream_id: "",
      },
      stream_url: "webrtc://dsm.ccom.com.cn:1990/stream/",
      // 图片地址
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      posterImg: "",
      ws_bind_d_ids: [],
      ws_id: "",
      d_id: "",
      // 查询参数
      queryParam: {
        ws_name: "",
        w_name: "",
        w_gj_name: "",
        bc_name: "",
        nowork: "",
        ws_bind_time_start: "",
        ws_bind_time_end: "",
        ws_bind_status: "2",
        ws_status: "1",
        d_status: "1",
        ws_service_type: "",
        ws_attendance: "",
        ws_model: "1",
        ChangeIdSetInterval: null,
      },
      arrList: [],
      info: {},
      change_index: 0,
    };
  },
  created() {
    // 获取工地列表并开始播放直播
    this.GetSiteList();
  },
  watch: {},
  mounted() {
    // 静音
    this.handelPlay();

    // 轮流播放直播
    let i = 0;
    // this.ChangeIdSetInterval = setInterval(() => {
    //   let length = this.arrList.length;
    //   i++;
    //   if (i == length) {
    //     i = 0;
    //   }
    //   // 关闭正在播放的直播
    //   this.stopVideo();
    //   // console.log("change_index  下标", this.change_index);
    //   // 重新赋值id 开启新的直播
    //   this.info = this.arrList[i];
    //   // console.log("this.info", this.info);
    //   this.ws_id = this.info.ws_id;
    //   this.GetWsbinddevices();
    //   console.log(length, i);
    // }, 1000 * 20);

    // window.onunload = function (e) {
    //   window.clearTimeout(this.timer); // 清理定时器
    //   this.stopVideo();
    // };
    // window.addEventListener("beforeunload", (e) => {
    //   e = e || window.event;
    //   if (e) {
    //     this.stopVideo();
    //   }
    // });
  },
  methods: {
    // 获取工地信息
    GetSiteList() {
      // 汇总所有入参：表格分页，查询条件
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetServiceList(params)
        .then((res) => {
          let arr = res.data.data;
          this.arrList = res.data.data;
          // const url = this.pathUrl;
          console.log("获取工地信息", arr);
          this.info = arr[0];
        })
        .then(() => {
          this.ws_id = this.info.ws_id;
          this.GetWsbinddevices();
        });
    },
    // 查询工地绑定设备信息
    GetWsbinddevices() {
      const param = {
        ws_id: this.ws_id,
      };
      const orderParam = ["ws_id"];
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetWsbinddevices(params).then((res) => {
        this.ws_bind_d_ids = res.data;
        this.index1 = 0;
        this.ws_bind_d_ids.forEach((el, index) => {
          if (el.ddefault == 1) {
            this.d_id = el.did;
            this.getVideo();
          } else {
            this.index1++;
            // console.log("  this.index1+", this.index1);
            if (this.index1 == this.ws_bind_d_ids.length) {
              this.d_id = this.ws_bind_d_ids[0].did;
              this.getVideo();
            }
          }
        });
        // console.log(this.d_id, this.ws_bind_d_ids);
      });
    },

    // 查询视频推流、拉流地址
    getVideo() {
      const params = {
        ws_id: this.ws_id,
        d_id: this.d_id,
        // ws_id: ws_id,
        // d_id: d_id,
      };
      GetSiteVideo(params).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.video.com_only = res.data.com_only;
          this.video = res.data;
          this.posterImg = this.pathUrl + res.data.file_path;
          const url = res.data.video_url;
          const push_url = res.data.audio_url;
          this.video.stream_id = url.substring(url.lastIndexOf("/") + 1);
          this.video.push_stream_id = push_url.substring(
            push_url.lastIndexOf("/") + 1
          );
          // 拉流
          this.videoPlayer();
          // 推流
          this.startPublish(
            this.stream_url + this.video.push_stream_id,
            new Audio()
          );
        }
      });
    },
    // flv拉流播放视频
    videoPlayer() {
      var videoElement = document.getElementById("myVideo");
      this.startPlay(this.stream_url + this.video.stream_id, videoElement);
    },
    startPlay(url, video) {
      this.player = new SrsRtcPlayerAsync(video);
      this.player
        .play(url)
        .then(function (session) {})
        .catch(function (reason) {
          this.player.close();
          // console.error(reason);
        })
        .finally(() => {});
    },
    // 推流
    startPublish(url, audio) {
      this.publisher = new SrsRtcPublisherAsync(audio);
      // console.log("startPublish", this.publisher);
      const publisher = this.publisher;
      this.publisher.pc.onicegatheringstatechange = function (event) {
        if (publisher.pc.iceGatheringState === "complete") {
        }
      };
      this.publisher
        .publish(url)
        .then(function (session) {
          // console.log("sessionid", session.simulator, session.sessionid);
          publisher.mute();
        })
        .catch(function (reason) {
          // Throw by sdk.
          if (reason instanceof SrsError) {
            if (reason.name === "HttpsRequiredError") {
              alert(
                `WebRTC推流必须是HTTPS或者localhost：${reason.name} ${reason.message}`
              );
            } else {
              alert(`${reason.name} ${reason.message}`);
            }
          }
          // See https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
          if (reason instanceof DOMException) {
            if (reason.name === "NotFoundError") {
              // alert(
              //   `找不到麦克风和摄像头设备：getUserMedia ${reason.name} ${reason.message}`
              // );
            } else if (reason.name === "NotAllowedError") {
              alert(
                `你禁止了网页访问摄像头和麦克风：getUserMedia ${reason.name} ${reason.message}`
              );
            } else if (
              [
                "AbortError",
                "NotAllowedError",
                "NotFoundError",
                "NotReadableError",
                "OverconstrainedError",
                "SecurityError",
                "TypeError",
              ].includes(reason.name)
            ) {
              alert(`getUserMedia ${reason.name} ${reason.message}`);
            }
          }
          publisher.close();
          // console.error(reason);
        });
    },
    // 关闭直播
    stopVideo() {
      const params = {
        ws_id: this.ws_id,
        d_id: this.d_id,
        com_only: this.video.com_only,
      };
      // this.talkEndTest(); //断开通话
      if (this.publisher) {
        this.publisher.close();
      }
      if (this.flvPlayer) {
        clearInterval(this.timeout);
        this.flvPlayer.unload(); //取消数据流加载
        this.flvPlayer.detachMediaElement(); //将播放实例从节点中取出
        this.flvPlayer.destroy(); //销毁播放实例
        this.flvPlayer = null;
      }
      StopSiteVideo(params)
        .then((res) => {
          // this.$message.success("断开视频成功");
        })
        .catch(() => {
          // this.$message.error("断开视频失败");
        });
    },
    // 静音
    handelPlay() {
      // this.$refs.videoPlayer.play(); // 会触发videoPlay()函数
      let jingyin = document.getElementById("myVideo");
      jingyin.muted = true;
      // console.log("静音");
    },
    changeVideo(type) {
      let length = this.arrList.length;
      this.change_index = this.change_index + type;
      console.log("改变工地直播", type, length, this.change_index);
      if (this.change_index < length && this.change_index > -1) {
        // 关闭正在播放的直播
        this.stopVideo();
        console.log("change_index  下标", this.change_index);
        // 重新赋值id 开启新的直播
        this.info = this.arrList[this.change_index];
        console.log("this.info", this.info);
        this.ws_id = this.info.ws_id;
        this.GetWsbinddevices();
      }
      if (type > 0 && this.change_index == length) {
        this.change_index = length - 1;
        this.$message.warning("已是最后一条数据");
      } else if (type < 0 && this.change_index == -1) {
        this.change_index = 0;
        this.$message.warning("已是第一条数据");
      }
      // console.log(this.change_index);
    },
    //  handleVisiable(e) {
    // switch(e.target.visibilityState) {
    //   case 'prerender':
    //     console.log('网页预渲染，内容不可见')
    //     break;
    //   case 'hidden':
    //     console.log('内容不可见，处理后台、最小化、锁屏状态')
    //     alert('警告！你已经离开当前答题页面');
    //     break;
    //   case 'visible':
    //     console.log('处于正常打开')
    //     break;
    // }
    // }
  },
  beforeDestroy() {
    console.log("this.stopVideo();");
    this.stopVideo();
    clearInterval(this.ChangeIdSetInterval);
    this.ChangeIdSetInterval = "";
  },
};
</script>

<style lang="less" scoped>
.VideoSwiper {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  .btns {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    font-size: 30px;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(to top, #fff, #fff);
    background: -webkit-linear-gradient(
      top,
      rgb(255, 255, 255),
      rgb(255, 255, 255, 0)
    );

    background: linear-gradient(
      to top,
      rgb(255, 255, 255),
      rgba(255, 255, 255, 0)
    );
    .btns_name {
      font-size: 16px;
      font-weight: 800;
      color: #000000;
    }
    .pointer_icon {
      cursor: pointer !important;
    }
  }
}
// // 播放按钮
video::-webkit-media-controls-play-button {
  display: none !important;
}
// 时间轴
video::-webkit-media-controls-timeline {
  display: none !important;
}
</style>