var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"WantDimensionality"},[_c('div',{staticClass:"center1_wyzw"},[_vm._v("我要掌握")]),_c('div',{staticClass:"center1_gr"},[_c('a-popover',{attrs:{"placement":"rightTop","overlayClassName":"popstyle","getPopupContainer":(triggerNode) => {
          return triggerNode.parentNode;
        }}},[_c('template',{slot:"content"},_vm._l((_vm.center1_gr),function(item){return _c('p',{key:item.id,on:{"click":function($event){return _vm.GoToDetail(item, '工人维度')}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('template',{slot:"title"}),_c('div',[_c('img',{attrs:{"src":require("../../assets/images/home_img/users1.png"),"alt":""}}),_c('div',[_vm._v("工人维度")])])],2)],1),_c('div',{staticClass:"center1_fk"},[_c('a-popover',{attrs:{"placement":"rightTop","overlayClassName":"popstyle","getPopupContainer":(triggerNode) => {
          return triggerNode.parentNode;
        }}},[_c('template',{slot:"content"},_vm._l((_vm.center1_fk),function(item){return _c('p',{key:item.id,on:{"click":function($event){return _vm.GoToDetail(item, '访客维度')}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',[_c('img',{attrs:{"src":require("../../assets/images/home_img/user.png"),"alt":""}}),_c('div',[_vm._v("访客维度")])])],2)],1),_c('div',{staticClass:"center1_gd"},[_c('a-popover',{attrs:{"placement":"rightTop","overlayClassName":"popstyle","getPopupContainer":(triggerNode) => {
          return triggerNode.parentNode;
        }}},[_c('template',{slot:"content"},_vm._l((_vm.center1_gd),function(item){return _c('p',{key:item.id,on:{"click":function($event){return _vm.GoToDetail(item, '工地维度')}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',[_c('img',{attrs:{"src":require("../../assets/images/home_img/layers.png"),"alt":""}}),_c('div',[_vm._v("工地维度")])])],2)],1),_c('div',{staticClass:"center1_yz"},[_c('a-popover',{attrs:{"placement":"rightTop","overlayClassName":"popstyle","getPopupContainer":(triggerNode) => {
          return triggerNode.parentNode;
        }}},[_c('template',{slot:"content"},_vm._l((_vm.center1_yz),function(item){return _c('p',{key:item.id,on:{"click":function($event){return _vm.GoToDetail(item, '业主维度')}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',[_c('img',{attrs:{"src":require("../../assets/images/home_img/unlock.png"),"alt":""}}),_c('div',[_vm._v("业主维度")])])],2)],1),_c('div',{staticClass:"center1_gl"},[_c('a-popover',{attrs:{"placement":"rightTop","overlayClassName":"popstyle","getPopupContainer":(triggerNode) => {
          return triggerNode.parentNode;
        }}},[_c('template',{slot:"content"},_vm._l((_vm.center1_gl),function(item){return _c('p',{key:item.id,on:{"click":function($event){return _vm.GoToDetail(item, '管理维度')}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',[_c('img',{attrs:{"src":require("../../assets/images/home_img/copy.png"),"alt":""}}),_c('div',[_vm._v("管理维度")])])],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }