<template>
  <!-- <div>趋势图</div> -->
  <div class="">
    <div class="TendencyChart">
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="1" tab="抽烟人数趋势图">
          <TendencychartSmoke v-if="SmokeShow" />
        </a-tab-pane>
        <a-tab-pane key="2" tab="未穿制服" force-render>
          <TendencychartClothes v-if="ClothesShow" />
        </a-tab-pane>
        <a-tab-pane key="3" tab="未打卡" force-render>
          <TendencychartCard v-if="CardShow" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import TendencychartSmoke from "./TendencychartSmoke.vue";
import TendencychartClothes from "./TendencychartClothes.vue";
import TendencychartCard from "./TendencychartCard.vue";
export default {
  name: "TendencyChart",
  components: {
    TendencychartSmoke,
    TendencychartClothes,
    TendencychartCard,
  },
  data() {
    return {
      CompaniesValue: "",
      filters: {},
      IsShowCompanyList: false,
      queryParam: {},
      dateFormatValue: "X",
      defaultValueStart: "",
      defaultValueEnd: "",
      item: "date",
      SmokeShow: false,
      ClothesShow: false,
      CardShow: false,
    };
  },
  created() {
    setTimeout(() => {
      this.SmokeShow = true;
    }, 10);
  },
  watch: {},
  mounted() {},
  methods: {
    moment,
    callback(key) {
      console.log(key);
      if (key == 1) {
        this.SmokeShow = true;
        this.ClothesShow = false;
        this.CardShow = false;
      } else if (key == 2) {
        this.SmokeShow = false;
        this.ClothesShow = true;
        this.CardShow = false;
      } else if (key == 3) {
        this.SmokeShow = false;
        this.ClothesShow = false;
        this.CardShow = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.TendencyChart {
  width: 100%;
  height: 100%;
  text-align: left;
}
/deep/.DeviceState_select {
  display: flex;
  justify-content: space-between;
}
.SiteNeat_date {
  display: flex;
  flex-wrap: nowrap;
  // background: pink;
  width: 55%;
}
/deep/ .dateRangeBTN {
  padding: 2px !important;
  // margin-right: 1px;
}
.dateRangeBTNName {
  display: flex;
  flex-wrap: nowrap;
  // width: 18%;
}
.dateRange_display {
  display: flex;
  flex-wrap: nowrap;
}
.start_datePicker_only {
  width: 100%;
}
.datePick_out {
  display: flex;
  flex-wrap: nowrap;
  width: 82%;
  // margin-right: 0;
  // .start_datePicker {
  //   // width: 49%;
  // }
}
.start_datePicker_only {
  width: 100%;
}
.dateRange_line {
  color: #ccc;
  padding: 0 3px;
}
.search-default-value {
  width: 35%;
}
</style>