var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"SiteWarn"},[_c('div',{staticClass:"warnTop"},[_vm._v("告警")]),_c('div',{staticClass:"warn_marquee"},[_c('marquee',{staticClass:"marquee_flex",attrs:{"loop":"1"}},_vm._l((_vm.warnList1),function(item,index){return _c('span',{key:index,staticClass:"mar_left",style:({
          backgroundColor: item.bgColor,
        })},[_vm._v(_vm._s(item.siteName + item.warnType)+" ")])}),0),_c('marquee',{staticClass:"marquee_flex",attrs:{"loop":"1"}},_vm._l((_vm.warnList2),function(item,index){return _c('span',{key:index,staticClass:"mar_left",style:({
          backgroundColor: item.bgColor,
        })},[_vm._v(_vm._s(item.siteName + item.warnType)+" ")])}),0),_c('marquee',{staticClass:"marquee_flex",attrs:{"loop":"1"}},_vm._l((_vm.warnList3),function(item,index){return _c('span',{key:index,staticClass:"mar_left",style:({
          backgroundColor: item.bgColor,
        })},[_vm._v(_vm._s(item.siteName + item.warnType)+" ")])}),0)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }